import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;

export default class ShieldService {

  static async productById(productId) {
    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/gext/productById/${productId}`
    );
    return shieldRes.data.data;
  }

  static async createForm(propietarioData, productId) {

    try {

      var params = { propietarioData, productId }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/form/create`,
        {
          params,
        }
      );
      return shieldRes.data;

    } catch (error) {
      return 'failed'
    }

  }

}
