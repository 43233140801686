<template>
  <div class="gext">
    <div style="background-color: #f6f6f6; padding-bottom: 2rem">
      <div style="border-bottom: 1px solid #8a8787; margin-right: 3rem; margin-left: 3rem;"></div>
      <v-container>
        <h1 style="font-weight: 400; text-align: center">
          Activa tu garantía extendida llenando este formulario!
        </h1>
        <h3 style="font-weight: 400; text-align: center">
          Felicidades, ya cuentas con la extensión de la garantía extendida de REFURBI para tu
          Producto, para poder activar tu cobertura, por favor llena los siguientes campos:
        </h3>
        <v-card elevation="0">
          <v-card-text v-if="error">
            <h2>Ocurrio un error</h2>
            <p>
              Hubo un error al obtener los datos de tu producto. Verifica que la
              URL sea correcta e intenta nuevamente.
            </p>
          </v-card-text>
          <v-card-text v-else>
            <v-stepper elevation="0" v-model="step">
              <v-stepper-header>
                <v-stepper-step :color="color" :complete="step > 1" step="1">
                  Datos de tu producto
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :color="color" step="2">
                  Rellenar datos
                </v-stepper-step>

              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content class="px-0" step="1">

                  <div class="text-center" v-if="loadData">
                    <v-progress-circular :size="50" :color="color" indeterminate></v-progress-circular>
                  </div>

                  <div v-else>
                    <h3 class="mb-4">Datos de tu producto</h3>

                    <v-col :class="columnas">
                      <div>
                        <label :class="vistalabel">Categoría:</label>
                        <v-text-field v-model="productoData.product_category" required solo disabled>
                        </v-text-field>
                      </div>

                      <div>
                        <label :class="vistalabel">Modelo:</label>
                        <v-text-field v-model="productoData.product_model" required solo disabled>
                        </v-text-field>
                      </div>

                      <div>
                        <label :class="vistalabel">SKU:</label>
                        <v-text-field v-model="productoData.product_sku" required solo disabled>
                        </v-text-field>
                      </div>

                    </v-col>

                    <v-btn :color="color" @click="step = 2, stepsLeft = 1" dark>
                      Aceptar
                    </v-btn>
                  </div>

                </v-stepper-content>

                <v-stepper-content class="px-0" step="2">

                  <v-form ref="form" v-model="valid">
                    <v-container style="justify-content: center; display: flex; flex-direction: column">

                      <v-col :class="columnas">

                        <div>
                          <label :class="vistalabel">Cédula de ciudadanía o número de documento de indentidad:</label>
                          <v-text-field v-model="propietarioData.nIdentClient" :rules="identRules" label="Ej: 900000"
                            required filled solo>
                          </v-text-field>
                        </div>

                        <div>
                          <label :class="vistalabel">Fecha expedición cédula:</label>
                          <v-text-field type="date" v-model="propietarioData.fechExpedicion" :rules="cedulaRules"
                            label="Ej: 30 ENE 2031" required filled solo return-object>
                          </v-text-field>
                        </div>

                        <div>
                          <label :class="vistalabel">Número serial de producto que adquiriste en Refurbi:</label>
                          <v-text-field v-model="propietarioData.sProducto" :rules="serialRules" label="Ej: 52364XXX"
                            required filled solo>
                          </v-text-field>
                        </div>

                        <div>
                          <label :class="vistalabel">IMEI producto que adquiriste en Refurbi:</label>
                          <v-text-field v-model="propietarioData.imei" label="Ej: 351910100205769" :rules="imeiRules"
                            required filled solo return-object>
                          </v-text-field>
                        </div>

                        <div>
                          <label :class="vistalabel">IMEI 2 producto que adquiriste en Refurbi:</label>
                          <v-text-field v-model="propietarioData.imei2" label="Ej: 351910100205769" :rules="imeiRules"
                            required filled solo return-object>
                          </v-text-field>
                        </div>

                      </v-col>

                    </v-container>
                  </v-form>

                  <div style="padding-left: 25%;">
                    <v-btn class="butoon" style="width: 200px; height: 50px; background: #0069B6 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #767676; border: 1px solid #DEDEDE;
                                                border-radius: 30px; opacity: 1;  text-align: center; font: normal normal normal 15px/15px Arial; letter-spacing: 0.66px; color: white; text-transform: 
                                                uppercase; opacity: 1;" @click="validate()">
                      Enviar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn class="butoon" style="width: 200px; height: 50px; background: #ce3357 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #767676; border: 1px solid #DEDEDE;
                                                border-radius: 30px; opacity: 1;  text-align: center; font: normal normal normal 15px/15px Arial; letter-spacing: 0.66px; color: white; text-transform: 
                                                uppercase; opacity: 1;" @click="step = 1">
                      Atrás
                    </v-btn>

                    <br>
                    <br>
                    
                  </div>

                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {
  data() {
    return {
      propietarioData: {
        nFactura: null,
        nIdentClient: null,
        sProducto: null,
        imei: null,
        imei2: null,
        fechExpedicion: null,
      },
      valid: false,
      productoData: {},
      loadData: true,
      columnas: null,
      vistalabel: null,
      step: 1,
      stepsLeft: 2,
      color: process.env.VUE_APP_COLOR,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      productId: null,
      customer: null,
      error: null,
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        // (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
      ],
      cedulaRules: [
        (v) => !!v || "El número de cedula es requerido",
        // (v) => /^[0-9]*$/.test(v) || "Ingrese sólo números",
      ],
      imeiRules: [
        (v) => !!v || "Debe ingresar el imei del producto",
        // (v) => /^[0-9]*$/.test(v) || "Ingrese sólo números",
      ],
      facturaRules: [
        (v) => !!v || "Debe ingresar el número de factura",
        // (v) => /^[0-9]*$/.test(v) || "Ingrese sólo números",
      ],
      identRules: [
        (v) => !!v || "Debe ingresar el número de indentificación del producto",
        // (v) => /^[0-9]*$/.test(v) || "Ingrese sólo números",
      ],
      serialRules: [
        (v) => !!v || "Debe ingresar el serial del producto",
      ],
    };
  },
  beforeMount() {
    if (this.screen > 800) {
      this.vistalabel = 'label'
      this.columnas = 'columnas'
    } else if (this.screen <= 800) {
      this.vistalabel = 'label2'
      this.columnas = 'columnas2'
    }

  },
  mounted() {
    const query = this.$route.query;
    if (query.xlm) {
      this.productId = query.xlm
      this.getProduct()
    }
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        var form = await ShieldService.createForm(this.propietarioData, this.productId)
        if (form.status == 'ok') {
          swal({
            title: "Formulario completado correctamente!",
            text: "Su información fue procesada adecuadamente.",
            icon: "success",
            button: "Ok",
          }).then(() => {
            window.location.pathname = '/successForm'
          });
        } else {
          swal({
            title: "Ups! algo salió mal.",
            text: "Por favor intentelo nuevamente",
            icon: "danger",
            button: "Ok",
          }).then(() => {
            window.location.pathname = 'form'
          });
        }
      }
    },
    async getProduct() {
      try {
        this.loadData = true
        var product = await ShieldService.productById(this.productId)
        this.productoData = product
        this.propietarioData.nFactura = this.productoData.invoice_number
        console.log('Form get saved successfully');
        this.loadData = false
      } catch (error) {
        console.error(error);
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.columnas {
  display: flex;
}

.columnas2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

.labelNamePatente {
  margin-left: 15%;
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.labelResponce {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-left: -10%;
}


.check {
  display: flex;
  justify-content: center;
}

.v-stepper__header {
  box-shadow: none;
}

.container {
  max-width: 900px !important;
}

.v-stepper__content {
  display: block;
  max-width: 500px !important;
  margin-left: auto;
  margin-right: auto;
}

.price-container {
  text-align: center;

  .price {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 3rem;
  }
}

.gext-container {
  display: block;
  position: relative;
  text-align: center;

  .gext-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.25;
    width: 120px;
    height: 65px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    .title,
    .desc {
      cursor: pointer;
    }

    &.active {
      color: white;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }
  }
}

.payment-container {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;

  .payment-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .payment-icon {
      height: 65px;
      display: inline-block;
      position: relative;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      padding: 0.5rem 1rem;

      // border: 1px solid #f5f5f5;
      cursor: pointer;
    }
  }
}
</style>